/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IGuest } from '../../../../api/guest/types';
import { getDurationInMinutes } from '../../../../utils/utils';
import { selectCharge } from '../../../../store/charge/charge.selector';
import chargeStyles from '../../charge.styles';
import { getInvoiceLink } from '../../../../api/guest/guest.api';
import chargeWaitStyles from '../charge-wait/charge-wait.styles';
import { AppTheme } from '../../../../app.theme';

import chargeFinishedStyles from './charge-finished.styles';

type PropTypes = {
    guest: IGuest;
};

export const ChargeFinished: FC<PropTypes> = ({ guest }) => {
    const { buttonPrimaryColor, buttonPrimaryTextColor } = AppTheme();
    const intl = useIntl();
    const navigation = useNavigate();
    const { entry: charge } = useSelector(selectCharge);

    let percent = Math.round((charge.energy * 100) / guest.energy);
    if (percent > 100) percent = 100;
    const duration = getDurationInMinutes(charge.startAt, charge.stopAt);

    const invoiceLink = (guestId: string) => {
        return getInvoiceLink(guestId);
    };

    return (
        <div css={chargeFinishedStyles.content}>
            <h4 css={chargeWaitStyles.containerTitle}>
                {intl.formatMessage({ id: 'chargeFinished' })}
            </h4>

            <div css={chargeFinishedStyles.info}>
                <div css={[chargeStyles.battery, chargeFinishedStyles.batteryFinished]}>
                    <div
                        css={[chargeStyles.batteryEmpty, chargeFinishedStyles.batteryFinishedEmpty]}
                        style={{ width: `${100 - percent}%` }}
                    />
                    <div
                        css={[
                            chargeStyles.batteryPercent,
                            chargeFinishedStyles.batteryFinishedPercent,
                        ]}>
                        {percent}
                    </div>
                </div>

                <div css={chargeFinishedStyles.infoBlock}>
                    <div css={chargeFinishedStyles.title}>
                        {intl.formatMessage({ id: 'consumed' })}
                    </div>
                    <div css={chargeFinishedStyles.value}>{(charge.energy / 1000).toFixed(2)}</div>
                    <div css={chargeFinishedStyles.unit}>
                        {intl.formatMessage({ id: 'unit.kWh' })}
                    </div>
                </div>

                <div css={chargeFinishedStyles.infoBlock}>
                    <div css={chargeFinishedStyles.title}>
                        {intl.formatMessage({ id: 'duration' })}
                    </div>
                    <div css={chargeFinishedStyles.value}>{duration}</div>
                    <div css={chargeFinishedStyles.unit}>{intl.formatMessage({ id: 'min' })}</div>
                </div>
            </div>

            <div css={chargeFinishedStyles.actionWrapper}>
                <a
                    css={[
                        chargeFinishedStyles.downloadCheck,
                        { backgroundColor: buttonPrimaryColor, color: buttonPrimaryTextColor },
                    ]}
                    download
                    href={invoiceLink(guest._id)}>
                    {intl.formatMessage({ id: 'downloadCheck' })}
                </a>
                <div css={chargeFinishedStyles.homePage} onClick={() => navigation('/')}>
                    {intl.formatMessage({ id: 'homePage' })}
                </div>
            </div>
        </div>
    );
};
